import { mapActions, mapGetters } from "vuex";

export default {
    name: "quiz",
    data() {
        return {
            answers: [],
        };
    },
    components: {},
    computed: {
        ...mapGetters({
            quizInfo: "courses/quizInfo",
            quizResultInfo: "courses/quizResultInfo",
        }),
    },
    watch: {},
    created() {
        if (!this.quizResultInfo) {
            this.goToProfile();
        }
    },
    methods: {
        goToProfile() {
            this.$router.push({ name: "platform" });
        },
    },
};
